import {useStaticQuery, graphql} from 'gatsby';

export const useTrademarks = () => {
  const {allTrademarksJson} = useStaticQuery(
    graphql`
      query Trademarks {
        allTrademarksJson {
          edges {
            node {
              title
              description
            }
          }
        }
      }
    `,
  );

  return allTrademarksJson.edges;
};
