import {useStaticQuery, graphql} from 'gatsby';

export const useTerms = () => {
  const {allTermsJson} = useStaticQuery(
    graphql`
      query Terms {
        allTermsJson {
          edges {
            node {
              title
              description
            }
          }
        }
      }
    `,
  );

  return allTermsJson.edges;
};
