import React from 'react';

// Layout
import Layout from '../containers/layout/Layout';

// Components
import TextWidget from '../components/common/text-widget/TextWidget';

// GraphQL
import {useTerms} from '../graphql/terms/terms';
import {useTrademarks} from '../graphql/terms/trademarks';

const Terms = () => {
  const terms = useTerms();
  const trademarks = useTrademarks();

  return (
    <Layout>
      <TextWidget data={terms} className="text-widget--terms">
        <p>
          Copyright © 1995-
          {new Date().getFullYear()}
          by Ensemble. All rights reserved.
        </p>
      </TextWidget>
      <TextWidget data={trademarks} className="text-widget--terms" />
    </Layout>
  );
};

export default Terms;
